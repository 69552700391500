import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import ButtonContainer from 'components/Location/ButtonContainer'
import ReviewBox from 'components/Location/ReviewBox'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledImg = styled(Img)`
  height: 100%;
  z-index: -1;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: #fff;

  @media screen and (max-width: 767px) {
    margin-top: -80px;
  }
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const StyledCustomLink = styled(CustomLink)`
  p {
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light};
    border-radius: 5px;
    padding-bottom: 0 !important;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
  }

  &:hover {
    p {
      transform: scale(1.05);
    }
  }
`


const AbcZwemlesPage = ({pageContext}) => {
  const { abczwemles, workings } = useStaticQuery(graphql`
    {
      abczwemles: wordpressPage(wordpress_id: {eq: 429}) {
        title
        path
        slug
        acf {
          banner{
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
          }
          omschrijving
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }

      workings: wordpressPage(wordpress_id: {eq: 275}) {
        acf {
          workings {
            title
            points {
              text
            }
          }
        }
      }
    }
  `)
  
  return (
    <Layout>
      <SEO yoast={{ meta: abczwemles.yoastMeta }} pathname={abczwemles.path} />
      <div>
        <ImageContainer>
          <StyledImg className="d-flex" alt="" fluid={abczwemles.acf.banner.image.localFile.childImageSharp.fluid} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light font-weight-xl">{`${abczwemles.title}`}</h2>
              <h3 className="font-size-l mb-0 d-lg-block d-none font-family-third color-text-contrast">Nu en later, vol vertrouwen in het water!</h3>
            </div>
          </ImageContainerText>
        </ImageContainer>
        <div className="container mb-5 pb-3 pb-lg-5">
          <div className="row ">
            <div className="col-lg-8 pt-3 pt-lg-5 pr-lg-5">
              {parse(abczwemles.acf.omschrijving)}
            </div>
            <div className="col-lg-4">
            <StyledCustomLink to="/wachtlijst" className="d-flex d-lg-none justify-content-start mb-3">
                <p className="mb-0 font-weight-l">Inschrijven</p>
              </StyledCustomLink>
              <StyledCustomLink newPage external to="https://snorkeltje.i-reserve.net/" className="d-flex d-lg-none justify-content-start mb-3">
                <p className="mb-0 font-weight-l">Reserveren en afmelden</p>
              </StyledCustomLink>
              <div>
                <ButtonContainer inschrijfUrl='/wachtlijst' className="d-none d-lg-flex" />
                <ReviewBox className="d-none d-lg-flex" />
              </div>
            </div>
          </div>
        </div>
        <div className="py-lg-5" />
      </div>
    </Layout>
  ) 
}

export default AbcZwemlesPage
